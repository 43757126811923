<template>
  <div class="main">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="">
        <div class="box center mB20" style="justify-content: space-between">
          <div class="box center">
            <el-form-item label="计算方案" prop="period" style="margin-right: 44px">
              <el-select @change="clearData" v-model="ruleForm.period">
                <el-option v-for="item in periodList" :key="item.name" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <template>
              <el-form-item label="数据区间" class="" prop="startTime">
                <el-date-picker @change="clearData" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.startTime"> </el-date-picker>
              </el-form-item>
              <span class="line">-</span>
              <el-form-item prop="endTime">
                <el-date-picker disabled @change="clearData" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.endTime">
                </el-date-picker>
              </el-form-item> 
            </template>
          </div>

          <!-- <el-button @click="queryData" class="mL44 dataQuery">查询</el-button> -->
          <div class="box center">
            <div class="btnSelect1 mR20 pointer" @click="save" :class="!myOption ? 'disable_icon' : ''">保存结果</div>
            <div class="btnSelect2 pointer" @click="getCalcData">计算</div>
          </div>
        </div>
      </div>
    </el-form>
    <div style="width: 100%" v-show="!resultFlag" class="">
      <div class="box" style="width: 100%">
        <!-- width: 70% -->
        <div style="position: relative;" class="mR20">
          <div class="myChart-leage"></div>
          <div class="myChart" id="myChart"></div>
        </div>
        <!-- :span-method="objectSpanMethod" width: 30%"-->
        <div style="" class="flex1">
          <template v-if="num.length > 0">
            <!-- historyTable -->
            <el-table class="" :row-class-name="tableRowClassName" :data="num" style="width: 100%">
              <el-table-column align="center" prop="id" label="序号" min-width="50px">
                <template scope="scope">
                  <span>{{ (1 - 1) * num.length + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column align="center" :label="num2" prop="num1"> </el-table-column>
              <el-table-column align="center" :label="num1" prop="num2"> </el-table-column>
            </el-table>
          </template>
        </div>
      </div>

      <div class="dataMain" v-if="pinlvList.length > 0">
        <ul class="dataList box center dataListbg1" >
          <li>阶数</li>
          <li v-for="(item, index) in pinlvList" :key="index">
            {{ index + 1 }}
          </li>
        </ul>
        <ul class="dataList box center dataListbg2">
          <li>频率</li>
          <li v-for="(item, index) in pinlvList" :key="index">
            {{ parseInt(item) == 9999 ? "-" : item }}
          </li>
        </ul>
        <ul class="dataList box center dataListbg3">
          <li>阻尼比</li>
          <li v-for="(item, index) in zuniList" :key="index">
            {{ parseInt(item) == 9999 ? "-" : item }}
          </li>
          <li v-if="pinlvList.length -  zuniList.length > 0" v-for="(item, index) in pinlvList.length -  zuniList.length" :key="index">
            {{  "-"  }}
          </li>
        </ul>
      </div>
    </div>
    <div class="noDataMain" v-if="resultFlag">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    <!-- <div class="noDataMain" v-show="showNoData">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";
import echarts from "echarts";
let option = {
  dataZoom: [
    {
      type: "inside",
      realtime: true,
      start: 0,
      end: 100,
    },
  ],
  grid: {
    top: "50",
    left: "40",
    right: "20",
    bottom: "60",
  },
  xAxis: {
    nameTextStyle: {
      color: "#fff",
      padding: [61, 0, 0, -40],
    },
    name: "f(Hz)",
    axisLabel: {
      color: "#fff",
      fontSize: 14,
    },
    axisLine: {
      lineStyle: {
        color: "#33393A",
        type: "dashed",
      },
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: "#33393A",
      },
    },
  },
  yAxis: [
    {
      name: "number of poles",
      nameTextStyle: {
        color: "#DAF704",
        padding: [0, 0, 10, 130],
        fontSize: 16,
      },
      type: "value",
      axisLabel: {
        color: "#fff",
        fontSize: 14,
      },
      axisLine: {
        lineStyle: {
          color: "#979797",
        },
      },
      splitLine: {
        lineStyle: {
          color: "#33393A",
          type: "dashed",
        },
      },
      axisTick: {
        show: false,
      },
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#979797",
        },
      },
      axisTick: {
        show: false,
      },
    },
  ],
  legend: {
    data: [
      { name: "new pole" },
      { name: "stable pole" },
      { name: "stable freq. & MAC" },
      { name: "stable freq. & damp." },
      { name: "stable freq." },
      {
        name: "pwelch谱值",
        icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADaADAAQAAAABAAAADQAAAACpyhiuAAAAIUlEQVQoFWNgGH6AsevF//+keouJVA0g9YNcEzleGux6AG4JA3rtz4nzAAAAAElFTkSuQmCC",
      },
    ],
    itemHeight: 11,
    itemWidth: 11,
    top: 6,
    right: 20,
    textStyle: {
      color: "#fff",
      padding: [0, 10, 0, 5],
    },
    itemGap: 50,
  },
  tooltip: {
    show: true,
    trigger: "axis",
    confine: true,
    position: function (point, params, dom, rect, size) {
      dom.style.transform = "translateZ(0)";
    },
  },
  series: [
    {
      name: "new pole",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol:
        "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAACwAAAAChLCZNAAAArElEQVQoFWNkQAO5//+z/7nPcIuZhcFyihzjMzRpBiZ0Aa73DBxAMTmghBC6HIiPoQGbImQxkjUw5r79z8fwnYEPZsrfPwy8//8xXGNgZnBlYWa4ARP/85fh63R5xveMWff+P/7PwCADk8BFMzIw/BRjZBBgYeZn0CbWhgZ5xh9Ajaig/N1//k8fGD6wsjDoTpJjvIIqS49QwgjWb4IMP4DOePSPgeEdunNAfADyMTND4mn9UAAAAABJRU5ErkJggg==",
      itemStyle: {
        color: "#69DEFF",
      },
    },
    {
      name: "stable pole",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol: "circle",
      itemStyle: {
        color: "#FF7575",
      },
    },
    {
      name: "stable freq. & MAC",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol:
        "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAACwAAAAChLCZNAAABIUlEQVQoFY1RsUoDURCcfXfGykYrCxGEcOJ1FlYBwc7WIlUMkYBg5Q8YCJgf8AuUIIHYWthZiYWdhlMTQQgi2NnYhHt367wjZ5UEt3j7hp3ZHXYF49CovojENiBaAnQdKm/M9/C8loQXXznPuI8+7W8jiXskW6IjmIVleKiz9I0kfdSoupsLRF+qS7Dag/ErEp7f5oU8k7yFFNcoYFOC9qeBRQOQy0lkJ5Kw/cB0hpG2HDZQlOi368DUMHNXEPIYhr4DFLzBVLIrbKy8s+mqatPnBOkjjoOZguePNTYeijQtJ+COTsszBWlcpnXynCUfp1xlRaODnUmibEvAMeblxNXFPdkdIB2iDlGXd3gFfopIdI+UQ96kxm3d/Aky0T8v/QswsmtziQfLwgAAAABJRU5ErkJggg==",
      itemStyle: {
        color: "#FFD25D",
      },
    },
    {
      name: "stable freq. & damp.",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol: "rect",
      itemStyle: {
        color: "#99ED6A",
      },
    },
    {
      name: "stable freq.",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol:
        "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAAt0lEQVQoFZVSORLCMAxc6aHQQQOBHlLBA0hSAZX5Ev+xkOyxsTMOM7jxoV1ptTKJO70BetD6OuHHEtfvILJhgO+ADPLquyW8uPMe8CMIz4AxtlbyMVDTcmye0MBzUgtMZb5YQQbt6aDlvWqeQHyk1WVMuIpgj1+SXmZgi2vT/62KkLNr5iBL/G3Rvdx04UbrLehpuZGEZlKYhdY1cBxK7UYi2J6lgju2cZve0roSbOf4bVh/gt9+AGA+gP/tCFrMAAAAAElFTkSuQmCC",
      itemStyle: {
        color: "#FFA07B",
      },
    },
    {
      name: "pwelch谱值",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#8AE8FF",
      },
      lineStyle: {
        width: 1,
      },
    },
  ],
};
export default {
  name: "modalAnalysis",
  data() {
    return {
      showNoData: false,
      resultFlag: false,
      projectId: "",
      ruleForm: { period: "", startTime: "", endTime: "" },
      rules: {},
      periodList: [
        { name: "历史查询", id: 1 },
        { name: "重新计算", id: 2 },
      ],
      // jieshuList:[1,2,3,4,5,6],
      pinlvList: [],
      zuniList: [],
      tableColumns: [],
      tableData: [],
      isShowMain: false,
      loading: false,
      num: [],
      sure: "0",
      num1: "本次峰值（dBW/Hz）",
      num2: "优势频率（Hz）",
    };
  },
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },
  mounted() {
    this.listData(); //获取下拉内容
    this.getStartTime();
    this.$bus.$on("changeList", this.changeList);
    // setTimeout(() => {
    //   this.queryData();
    // }, 0);
  },
  methods: {
    changeList(e) {
      this.listData(); //获取下拉内容
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    //  下拉菜单
    listData() {
      this.$axios
        .get(`${this.baseURL}modal/select/list/${this.projectId}`)
        .then((res) => {
          console.log("下拉信息", res.data.data);
          this.periodList = res.data.data;
          if (this.periodList.length) {
            this.ruleForm.period = this.periodList[0].id;
            this.queryData();
          } else {
            this.ruleForm.period = "";
          }
        })
        .catch((err) => {});
    },

    clearData() {
      this.isShowMain = false;
      this.sure = "0";
      var time = this.formDate(this.ruleForm.startTime);
      this.ruleForm.endTime = moment(time + 30 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    // YYYY-MM-DD hh:mm:ss'转时间戳
    formDate(time) {
      const thisTime = time.replace(/ /g, "/");
      var nowTime = new Date(thisTime);
      nowTime = nowTime.getTime();
      return nowTime;
    },

    queryData() {
      let period = this.ruleForm.period;
      this.isShowMain = true;
      if (!period) return;

      this.$axios.get(`${this.baseURL}modal/page/version/${period}?pageNum=1&pageSize=10`).then((res) => {
        let _id = res.data.data.records[0].id;
        if (_id) {
          this.getLineData(_id); //图表
          this.ruleForm.startTime = res.data.data.records[0].stime;
        }
      });
      //   if (period===1) { // 历史计算
      //     this.getTable()
      //   } else { // 重新计算
      //     if (this.ruleForm.modalNMin >= this.ruleForm.modalNMax) {
      //       this.$message({
      //         message: "最低阶次应小于最高阶次",
      //         type: "warning"
      //       });
      //       return
      //     }
      //     this.$nextTick(()=>{
      //       this.getLineData()
      //     })
      //   }
    },
    //计算
    getCalcData() {
      let projectId = this.$store.state.projectId;
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$axios.get(`${this.baseURL}modal/calculate/${this.ruleForm.period}?startTime=${this.ruleForm.startTime}&endTime=${this.ruleForm.endTime}`).then((res) => {
        res = res.data;
        this.loadingPage.close();
        // return;
        let data = res.data;
        // this.loadingPage.close();
        this.resultFlag = false;
        if (!data.length) {
          this.resultFlag = true;
          return;
        }
        if (res.errCode === "200") {
          this.sure = "1"; //可以保存
          /*图表散点展示：第一组：new pole
          第二组：stable pole
          第三组：stable freq. & MAC
          第四组：stable freq. & damp.
          第五组：stable freq.
          图表折线展示：第六组：[频率，幅值]列表展示：第七组：[频率，阻尼比]*/
          console.log("dadad",data);
          option.series[0].data = data[0];
          option.series[1].data = data[1];
          option.series[2].data = data[2];
          option.series[3].data = data[3];
          option.series[4].data = data[4];
          option.series[5].data = data[5];

          let pinlvList = [];
          let zuniList = [];
          this.pinlvList = [];
          this.zuniList = [];
          this.num = [];
          /*for (let item of data[6]) {
            pinlvList.push(item[0].toFixed(4))
            zuniList.push(item[1].toFixed(4))
          }*/
          for (let i = 0; i <data[6].length / 2 ; i++) {
            let item = data[6][i];
            pinlvList.push(item.toFixed(4));
          }
          for (let i = data[6].length / 2; i < data[6].length; i++) {
            let item = data[6][i];
            zuniList.push(item.toFixed(4));
          }

          var num1 = [];
          var num2 = [];
          var num = [];
          for (let i = 0; i < data[7].length / 2; i++) {
            let item = data[7][i];
            num1.push(item.toFixed(4));
          }
          for (let i = data[7].length / 2; i < data[7].length; i++) {
            let item = data[7][i];
            num2.push(item.toFixed(4));
          }
          num1.forEach((item, index) => {
            num.push({ num1: num1[index], num2: num2[index] });
          });

          this.num = num;
          console.log("num", this.num);

          //   console.log(pinlvList)
          //   console.log(zuniList)
          this.pinlvList = pinlvList;
          this.zuniList = zuniList;
          this.initEcharts();
        } else {
          this.loadingPage.close();
          this.$message.error(res.errMsg);
        }

        this.initEcharts();
      });
    },
    getStartTime() {
      this.ruleForm.endTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      var time = new Date().getTime() - 30 * 60 * 1000;
      this.ruleForm.startTime = moment(new Date(new Date().getTime() - 30 * 60 * 1000)).format("YYYY-MM-DD HH:mm:ss");
    },
    // 表格跨行
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    initEcharts() {
      let myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

    save() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      if (this.sure == "0") {
        this.$message.error("不能保存, 请先计算");
        return;
      }

      this.loadingPage = this.$loading({
        lock: true,
        text: "正在保存...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.$axios
        .get(`${this.baseURL}modal/save/calculate/${this.ruleForm.period}?startTime=${this.ruleForm.startTime}&endTime=${this.ruleForm.endTime}`)
        .then((res) => {
          console.log("保存结果", res);
          this.loadingPage.close();
          if (res.data.errCode == "200") {
            this.$message.success("保存成功");
          }
        })
        .catch((err) => {});
    },

    // 请求图表
    getLineData(id) {
      let projectId = this.$store.state.projectId;
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$axios.get(`${this.baseURL}modal/select/version/${id}`).then((res) => {
        console.log("3333模态返回", res);
        res = res.data;
        // return;
        let data = res.data;
        // if(data && data.length){
        //   let hasTag = false
        //   for (let i = 0; i < data.length; i++) {
        //     if (data[i].length) {
        //       hasTag = true
        //     }
        //   }
        //   if(hasTag){
        //     this.resultFlag = false
        //   }else{
        //     this.resultFlag = true
        //   }
        // }else{
        //   this.resultFlag = true
        // }

        this.loadingPage.close();
        if (res.errCode === "200") {
          if (data[0].length && data[1].length && data[2].length && data[3].length && data[4].length && data[5].length) {
            this.resultFlag = false;
          } else {
            this.resultFlag = true;
          }

          console.log(data);
          let arr = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].length) {
              arr.push("a");
            }
          }

          option.series[0].data = data[0];
          option.series[1].data = data[1];
          option.series[2].data = data[2];
          option.series[3].data = data[3];
          option.series[4].data = data[4];
          option.series[5].data = data[5];

          let pinlvList = [];
          let zuniList = [];
          this.pinlvList = [];
          this.zuniList = [];
          this.num = [];

          var data6 = data[6];
          for (let i = 0; i < 10; i++) {
            if(i < data6.length){
              pinlvList.push(data6[i]);
            }else{
              pinlvList.push("/");
            }
          }
          var data7 = data[7];
          for (let i = 0; i < 10; i++) {
            if(i < data7.length){
              zuniList.push(data7[i]);
            }else{
              zuniList.push("/");
            }
          }
          // data7.forEach((item, index) => {
          //   zuniList.push(item);
          // });

          // for (let i = 0; i < data[6].length / 2; i++) {
          //   let item = data[6][i];
          //   pinlvList.push(item.toFixed(4));
          // }
          // for (let i = Math.ceil(data[6].length / 2); i < data[6].length; i++) {
          //   let item = data[6][i];
          //   zuniList.push(item.toFixed(4));
          // }

          var num1 = [];
          var num2 = [];
          var num =  [];

          for (let i = 0; i < data[8].length; i++) {
            let item = data[8][i];
            num.push({
              num1:item[0],
              num2:item[1],
            })
          }
          // for (let i = Math.ceil(data[7].length / 2); i < data[7].length; i++) {
          //   let item = data[7][i];
          //   num2.push(item.toFixed(4));
          // }
          // num1.forEach((item, index) => {
          //   num.push({ num1: num1[index], num2: num2[index] });
          // });

          this.num = num;

          this.pinlvList = pinlvList;
          this.zuniList = zuniList;

          console.log(pinlvList);
          console.log(zuniList);

          this.initEcharts();
        } else {
          this.$message.error(res.errMsg);
        }

        this.initEcharts();
      });
    },

    getTable() {
      this.loading = true;
      let projectId = this.$store.state.projectId;
      let params = {
        projectId,
        time: this.ruleForm.year,
        sort: 1,
        size: 12, // 接口要求，暂定100，对前端目前没意义
      };
      this.$axios.get(`${this.baseURL}data/history/ssi/${projectId}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        console.log(res);
        if (data.length == 0) {
          return;
        }
        let tableData = [];
        let tableColumns = [];
        if (res.errCode === "200") {
          for (let item of data) {
            let obj1 = {
              date: item.time,
              name: "频率",
            };
            let obj2 = {
              date: item.time,
              name: "阻尼比",
            };
            let objValue1 = [];
            let objValue2 = [];
            tableColumns = [];
            for (let i = 0; i < item.data.length - 10; i++) {
              if (item.data[i] === 9999) {
                objValue1.push("-");
              } else {
                objValue1.push(item.data[i]);
              }
              tableColumns.push(i + 1);
            }
            for (let i = 10; i < item.data.length; i++) {
              if (item.data[i] === 9999) {
                objValue2.push("-");
              } else {
                objValue2.push(item.data[i]);
              }
            }
            obj1.value = objValue1;
            obj2.value = objValue2;
            tableData.push(obj1);
            tableData.push(obj2);
          }
          this.loading = false;
          this.tableColumns = tableColumns;
          this.tableData = tableData;
        } else {
          this.loading = false;
          this.$message.error(res.errMsg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 20px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 102px;
      padding: 0;
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }

    .line {
      padding: 0 10px;
    }

    .el-input {
      width: 258px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        width: 162px;

        .el-input__inner {
          padding: 0 0 0 10px;
          font-size: 14px;
        }

        .el-input__prefix {
          display: none;
        }
      }
    }

    .el-button.ml24 {
      margin-left: 24px;
    }
  }

  .myChart {
    width: 1200px;
    // width: 100%;
    height: 380px;
    // overflow: hidden;
    //height: 420px;
    background: #0f234c;
  }

  .dataMain {
    border: 1px solid #255292;
    margin-right: 8px;
    margin-top: 30px;

    .dataList {
      border-bottom: 1px solid #255292;

      li {
        display: inline-block;
        width:200px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        border-left: 1px solid #255292;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  // ::v-deep .historyTable{
  //   border: 1px solid #31363A;
  //   thead.is-group.has-gutter{
  //     tr:nth-child(2){display: none;}
  //     th{
  //       background-color: #31363A;
  //       border-color:#31363A;
  //     }
  //   }
  //   .el-table__body tr:hover > td{background-color: rgba(48, 46, 46, 0.11);}
  //   &.el-table--border::after, &.el-table--group::after{width: 0;}
  //   .el-table__header-wrapper{border-bottom: 1px solid #5E5E5F;}
  //   td{background-color: rgba(48, 46, 46, 0.11)}
  //   &.el-table td, &.el-table th.is-leaf{border-bottom: 1px solid #31363A !important;}
  //   &.el-table--border td, &.el-table--border th, &.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{border-right: 1px solid #31363A !important;}
  // }
  ::v-deep .smallInput {
    .el-input {
      width: 115px;

      .el-input__inner {
        padding: 0 15px;
      }
    }

    .el-input-number {
      width: 115px;

      span {
        display: none;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-input.is-disabled .el-input__inner{
  border: 1px solid rgba(151, 151, 151, 0.23);
}

.myChart-leage{
  width: 4px;
height: 12px;
opacity: 0.58;
background: #72D6F3;
position: absolute;
z-index: 999;
top: 10px;
left: 10px;
}
.dataListbg1{
  background: #122951;
}
.dataListbg2{
  background: #0C265B;
}
.dataListbg3{
  background: #091D46;
}
</style>
