<template>
  <div class="modelWin" v-if="open == 1">
    <div class="win winStyle">
      <div class="top">{{title}}</div>
      <div class="bottom">
        <div>
          <div class="box" style="width: 100%">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"
              :validate-on-rule-change="false" style="width:100%;display:block;">
              <div class="box" style="width: 100%">
                <el-form-item label="方案名称" style="margin-right: 100px" prop="name">
                  <el-input v-model="ruleForm.name" :disabled="disabled" style="margin-left:49px;"
                    class="padding-eft34">
                  </el-input>
                </el-form-item>
                <el-form-item label="振动测点" prop="targetId">
                  <el-select style="margin-left:49px" :disabled="disabled" multiple collapse-tags
                    v-model="ruleForm.targetId" placeholder="振动测点">
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item class="smallInput" label="时滞步长" prop="modalTimeLag">
                <el-input type="number" v-model="ruleForm.modalTimeLag" :disabled="disabled" style="margin-left:49px;"
                  class="padding-eft34" />
                <span class="fs18 mL10">s</span>
              </el-form-item>
              <!-- <el-form-item class="smallInput " label="最低阶次" prop="modalNMin">
                                <el-input-number :step-strictly="true" v-model="ruleForm.modalNMin" controls-position="right" :min="1" :max="10"></el-input-number>
                            </el-form-item> -->
              <el-form-item class="smallInput" label="最高阶数" prop="modalNMax">
                <el-input-number style="margin-left:49px;width:135px" :disabled="disabled" :step-strictly="true"
                  v-model="ruleForm.modalNMax" controls-position="right" :min="1" :max="10"></el-input-number>
              </el-form-item>
              <el-form-item label="定时计算">
                <el-switch active-color="#2667DB" style="margin-left:49px" v-model="ruleForm.status"
                  :disabled="disabled"></el-switch>
              </el-form-item>
              <!-- <el-form-item label="活动时间" required>
                                <el-col :span="11">
                                    <el-form-item prop="start">
                                        <el-date-picker type="date"
                                            :disabled="disabled"
                                            format="yyyy-MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                             placeholder="选择日期" v-model="ruleForm.start" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="0.5">-</el-col>
                                <el-col :span="11">
                                    <el-form-item  prop="end">
                                        <el-date-picker 
                                            :disabled="disabled"
                                            format="yyyy-MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="选择时间" v-model="ruleForm.end" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-form-item> -->

              <!-- 时分秒 -->
              <el-form-item class="smallInput" label="分析时段" prop="start" v-if="ruleForm.status == true">
                <el-col :span="4.5">
                  <el-time-picker @change="changeTime" type="date" placeholder="选择日期" v-model="ruleForm.start"
                    style="width: 100%;margin-left:49px;width:135px" :disabled="disabled"></el-time-picker>
                </el-col>
                <el-col class="line" :span="1" style="text-align:center;">-</el-col>
                <el-col :span="4">
                  <el-time-picker placeholder="选择时间" v-model="ruleForm.end" style="width: 135px" disabled>
                  </el-time-picker>
                </el-col>
              </el-form-item>
              <el-form-item class="smallInput" label="周期间隔" prop="period" v-if="ruleForm.status == true">
                <el-radio-group style="margin-left:49px" v-model="ruleForm.period" size="small" :disabled="disabled">
                  <el-radio-button round label="1">1天</el-radio-button>
                  <el-radio-button label="2">10天</el-radio-button>
                  <el-radio-button label="3">1月</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div></div> -->
        </div>
        <div class="box around">
          <div class="box">
            <div class="btnSelect1 mR20 pointer" @click="close">取消</div>
            <div class="btnSelect2 pointer" @click="save">保存</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";
export default {
  name: "modelWin",
  props: ["open", 'title'],
  //  props:{
  //      'open': String,
  //      'type': String,
  //      'item': Object
  //  },
  data() {
    return {
      projectId: "",
      disabled: "true",
      ruleForm: {
        name: "",
        targetId: [], //测点id
        type: "",
        modalTimeLag: "", //时至
        //   modalNMin: '',     //最低
        modalNMax: "", //最高
        status: "false", //定时
        start: "",
        end: "",
        period: "" //间隔
      },
      type: "",

      rules: {
        name: [{ required: true, message: "请输入方案名称", trigger: "blur" }],
        targetId: [
          { type: "array", required: true, message: "请至少选择一个", trigger: "change" }
          // { required: true, message: '请选择', trigger: 'change' }
        ],
        modalTimeLag: [{ required: true, message: "请输入", trigger: "blur" }],
        modalNMax: [{ required: true, message: "请输入", trigger: "blur" }],
        //   status: [
        //     { required: true, message: '请选择', trigger: 'change' }
        //   ],
        start: [
          // { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          { required: true, message: "请选择开始日期", trigger: "change" }
        ],
        end: [{ required: true, message: "请选择时间", trigger: "change" }],
        period: [{ required: true, message: "请选择", trigger: "change" }]
      },
      options: []
    };
  },
  watch: {
    type(n, o) {
      if (n == "add") {
        this.disabled = false;
      }
    }
  },

  //创建
  created() { },

  //安装
  mounted() {
    this.projectId = sessionStorage.getItem("projectId");
    this.modelData = sessionStorage.getItem("modelData");
    // this.$refs.ruleForm.clearValidate();
    // this.ruleForm = Object.assign({}, '')
    console.log("projectId", sessionStorage.getItem("projectId"));

    this.$bus.$on("modelItem", this.modelItem);
    this.$bus.$on("modelEdit", this.modelEdit);
    this.$bus.$on("modelAdd", this.modelAdd);
    this.targetData();
    this.$nextTick(() => {
      // this.$refs.ruleForm.resetFields();
    });
  },

  //方法
  methods: {
    changeTime(e) {
      console.log("e", e);
      console.log("1", this.ruleForm.start);

      var time = new Date(this.ruleForm.start).getTime();
      console.log("time", time);

      var time1 = time + 30 * 60 * 1000;
      console.log("time1", moment(time).format("YYYY-MM-DD HH:mm:ss"));

      this.ruleForm.end = moment(time1).format("YYYY-MM-DD HH:mm:ss");
      console.log("this.ruleForm.end", this.ruleForm.end);

      // this.ruleForm.end =  moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // var time = (new Date().getTime()) - 30*60*1000;
      // this.ruleForm.start = moment(new Date(new Date().getTime() - 30*60*1000)).format('YYYY-MM-DD HH:mm:ss')

      this.$nextTick(() => {
        this.$forceUpdate();
      });

      //   var time = this.formDate(this.ruleForm.start);
      // this.ruleForm.end = moment(time + 30*60*1000).format('YYYY-MM-DD HH:mm:ss')
    },

    //  关闭窗口
    close() {
      //   this.ruleForm = Object.assign({}, '')
      this.$bus.$emit("modelItem", {});
      this.$emit("changeState", "0");
    },
    //   详情传过来的。。不能修改只能看
    modelItem(e) {
      // this.targetData();
      this.ruleForm = {};
      this.type = "infor";
      console.log("e-ruleform", e);
      // this.state = 1;
      this.disabled = true;
      // return;
      if (e.status == 1) {
        var status = true;
      } else {
        var status = false;
      }
      var arr = [];
      // return;
      if (Object.keys(e).length == 0) {
        this.ruleForm = {
          name: "",
          targetId: [], //测点id
          type: "",
          modalTimeLag: "", //时至
          //   modalNMin: '',     //最低
          modalNMax: "", //最高
          status: "false", //定时
          start: "",
          end: "",
          period: "" //间隔
        };
        return;
      }
      var s = e.targetId;
      var target = s.split(",");
      var target1 = target.map(Number);

      this.ruleForm = {
        name: e.name,
        modalNMax: e.modalNMax,
        modalTimeLag: e.modalTimeLag,
        period: e.period,
        projectId: e.projectId,
        // "startTime": start,
        start: "2021-11-11 " + e.startTime,
        end: "2021-11-11 " + e.endTime,
        status: status,
        targetId: target1
      };
      console.log("ruleForm", this.ruleForm);
    },

    modelEdit(e) {
      // this.targetData();
      this.type = "edit";
      this.disabled = false;
      this.id = e.id;
      if (e.status == 1) {
        var status = true;
      } else {
        var status = false;
      }
      var arr = [];

      var s = e.targetId;
      var target = s.split(",");
      var target1 = target.map(Number);

      this.ruleForm = {
        name: e.name,
        modalNMax: e.modalNMax,
        modalTimeLag: e.modalTimeLag,
        period: e.period,
        projectId: e.projectId,
        // "startTime": start,
        start: "2021-11-11 " + e.startTime,
        end: "2021-11-11 " + e.endTime,
        status: status,
        targetId: target1
      };
      console.log("ruleForm", this.ruleForm);
    },

    modelAdd(e) {
      console.log("打开窗口");
      // this.targetData();
      this.type = "add";
      this.ruleForm = {
        name: "",
        targetId: [], //测点id
        type: "",
        modalTimeLag: "", //时至
        //   modalNMin: '',     //最低
        modalNMax: "", //最高
        status: "false", //定时
        start: "",
        end: "",
        period: "" //间隔
      };
      this.disabled = false;
      console.log("ruleform", this.ruleForm);
      this.$nextTick(() => {
        console.log(' this.$refs["ruleForm"]', this.$refs["ruleForm"], this);
        this.$refs["ruleForm"].resetFields();
      });
      // this.ruleForm.end =  moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // var time = (new Date().getTime()) - 30*60*1000;
      // this.ruleForm.start = moment(new Date(new Date().getTime() - 30*60*1000)).format('YYYY-MM-DD HH:mm:ss')
    },

    targetData() {
      this.$axios
        .get(`${this.baseURL}modal/vibration/${this.projectId}`)
        .then((res) => {
          console.log("测点列表", res.data.data);
          this.options = res.data.data;
        })
        .catch((err) => { });
    },

    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.getHttp();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    getHttp() {
      console.log("this.ruleForm.start", this.ruleForm.start, "this.ruleForm.end", this.ruleForm.end);
      if (!this.ruleForm.start && !this.ruleForm.end) {
        let now = Date.now();
        this.ruleForm.start = now - 1800000;
        this.ruleForm.end = now;
      }
      var start = "",
        end = "",
        status;
      start = moment(this.ruleForm.start).format("HH:mm:ss");
      end = moment(this.ruleForm.end).format("HH:mm:ss");
      if (this.ruleForm.status == true) {
        status = 1;
      } else {
        status = 0;
      }

      var data = {
        endTime: end,
        modalNMax: this.ruleForm.modalNMax,
        modalTimeLag: this.ruleForm.modalTimeLag,
        name: this.ruleForm.name,
        period: this.ruleForm.period,
        projectId: this.projectId,
        startTime: start,
        status: status,
        targetId: this.ruleForm.targetId.toString()
        // this.ruleForm.targetId
      };

      if (this.type == "edit") {
        data.id = this.id;
      }

      console.log("data", data);

      this.$axios
        .post(`${this.baseURL}modal/save`, data)
        .then((res) => {
          console.log("保存", res);
          if (this.type == "edit") {
            this.$message.success("编辑成功");
          } else {
            this.$message.success("新建成功");
          }
          this.$bus.$emit("changeList", 1);
          this.$emit("changeState", "0");
        })
        .catch((err) => { });
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-input {
  height: 32px;
  width: 312px;
}

.smallInput ::v-deep .el-input {
  width: 135px
}

::v-deep .el-input__icon {
  line-height: 32px;
}

::v-deep .el-input-number {
  width: 310px;
  line-height: 32px;
}

::v-deep .el-input-number.is-controls-right .el-input-number__decrease,
.el-input-number.is-controls-right .el-input-number__increase {
  line-height: 14px !important;
}

::v-deep .el-input-number__decrease {
  background: transparent !important;
}

::v-deep .el-input-number__increase {
  background: transparent !important;
}

::v-deep .el-input-number__increase {
  border: none;
}

.padding-eft34 ::v-deep .el-input__inner {
  padding-left: 34px;
}

::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
  border: none;
}

::v-deep .el-input-number.is-controls-right .el-input-number__increase {
  border: none;
}

// 分组
::v-deep .el-radio-button__inner {
  padding-left: 28px !important;
  padding-right: 28px !important;
  border-radius: 16px !important;
  margin-right: 4px !important;
}

::v-deep .el-radio-button__inner {
  background: #031339;
  border-color: #102046;
  border-radius: 16px;
  margin-right: 4px;
}

::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-color: #102046;
  border-radius: 16px;
  margin-right: 4px;
}

::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background: #2667db;
  border-radius: 16px;
  margin-right: 4px;
}

::v-deep .el-switch.is-checked .el-switch__core {
  background: #2667db !important;
}

.modelWin {
  // width:100%;
  // height: 100%;
  // position: absolute;
  // z-index: 99;
}

::v-deep .win {
  width: 1140px;
  height: 600px;
  position: absolute;
  left: 50%;
  margin-left: -570px;
  top: 150px;
  z-index: 9;

  .top {}

  .bottom {
    padding-top: 20px;
  }
}
</style>
