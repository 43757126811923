<template>
  <div class="plan">
    <modelWin :title="title" :open="open" @changeState="changeState"></modelWin>
    <div>
      <template  v-if="tableData.length"> 
        <el-table :row-class-name="tableRowClassName" :data="tableData" style="width: 100%">
          <el-table-column prop="code" label="计算方案编号" align="center"> </el-table-column>
          <el-table-column prop="name" label="方案名称" align="center"> </el-table-column>
          <el-table-column prop="" align="center" label="计算类型">
            <template>
              <div>定时计算</div>
            </template>
          </el-table-column>
          <el-table-column prop="period" align="center" label="周期间隔">
            <template slot-scope="scope">
              <div>{{ scope.row.period }}天</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="分析时段" align="center">
            <template slot-scope="scope"> {{ scope.row.startTime }}-{{ scope.row.endTime }} </template>
          </el-table-column>
          <el-table-column prop="targetName" label="测点名称" align="center"> </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="scope">
              <div class="box center">
                <div class="mR20" @click="inforData(scope.row)" :class="!myOption ? 'disable_icon' : ''">详情</div>
                <div class="mR20" @click="eidtData(scope.row)" :class="!myOption ? 'disable_icon' : ''">编辑</div>
                <div @click="del(scope.row)" :class="!myDel ? 'disable_icon' : ''">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <common-pagination v-if="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" />
    </div>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
import modelWin from "./modelWin";
import CommonPagination from "../../../components/Pagination";

export default {
  name: "plan",
  components: {
    modelWin,
    CommonPagination
  },
  data() {
    return {
      projectId: "",
      open: "",
      tableData: [],
      pageSize: 10, //
      currentPage: 1,
      total: 0,
      title: ''
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },

  //安装
  mounted() {
    //   获取列表
    this.listData();
  },

  //方法
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    listData() {
      this.$axios
        .get(`${this.baseURL}modal/page/${this.projectId}?pageNum=${this.currentPage}&pageSize=10`)
        .then((res) => {
          console.log("列表数据", res.data.data);
          var data = res.data.data.records;
          this.total = res.data.data.total;
          this.tableData = data;
        })
        .catch((err) => {});
    },

    inforData(item) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$bus.$emit("modelItem", item);
      this.title = '方案详情'
      this.open = 1;
    },

    //  关闭窗口
    changeState(e) {
      this.open = e;
      this.listData(); //重新读取数据
    },

    eidtData(item) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      this.$bus.$emit("modelEdit", item);
      this.title = '方案编辑'
      this.open = 1;
    },

    del(item) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }

      this.$confirm(`确认删除？`, "删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      })
        .then(() => {
          this.httpDel(item.id);
        })
        .catch(function () {});
    },
    httpDel(id) {
      this.$axios
        .delete(`${this.baseURL}modal/del/${id}`)
        .then((res) => {
          console.log("删除", res);
          this.listData(); //重新获取列表
        })
        .catch((err) => {});
    },
    // 点击分页
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.listData();
    }
  }
};
</script>
<style scoped lang="scss">
  .mR20{
    cursor: pointer;
  }
::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
