<template>
  <div class="modelIndex">
    <modelWin :open="open" :title="'新建方案'" :type="type" @changeState="changeState"></modelWin>
    <div class="mainBox box box_column">
      <div class="box justify box-bottom-border">
        <ul class="ul1 box center ulTab "  >
          <li class="textC pointer box center" v-for="(item, index) in list" :key="index" :class="active == item.id ? 'tab1' : ''" @click="changeTab(item.id)">
            <!-- <div style="width:100%">
                        <div>{{item.name}}</div> -->
            <!-- <div  v-if="active == item.id" class="fu">
                            <img src="../../../assets/images/Rectangle_bar.png" alt="">
                        </div>
                        <div>
                            <img src="" alt="">
                        </div> -->
            <!-- </div> -->
            <div style="width: 100%" class="fu">
              <div>{{ item.name }}</div>
              <div class="tiao" v-if="active == item.id"></div>
            </div>
            <div class="shu" v-if="index != list.length - 1"></div>
          </li>
        </ul>
        <div>
          <el-button @click="addData" class="mL44 dataQuery" :class="!myOption ? 'disable_icon' : ''">新建方案</el-button>
        </div>
      </div>
      <!-- <div class="heng"></div> -->
      <div class="flex1 main">
        <div v-if="active == 1">
          <online></online>
        </div>
        <div v-if="active == 2" style="height: 98%" class="main2">
          <modelAnalysisShow></modelAnalysisShow>
        </div>
        <div v-if="active == 3">
          <plan></plan>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import online from "./online";
import modelAnalysisShow from "./modelAnalysisShow";
import plan from "./plan";
import modelWin from "./modelWin";

export default {
  name: "modelIndex",
  components: {
    online,
    modelAnalysisShow,
    plan,
    modelWin
  },
  data() {
    return {
      list: [
        { id: "1", name: "在线计算" },
        { id: "2", name: "历史结果" },
        { id: "3", name: "方案查询" }
      ],
      active: "1",
      type: "add",

      //  窗口
      win: 1,
      ruleForm: { period: 2, startTime: "", endTime: "", year: "", modalTimeLag: 10, modalNMin: 1, modalNMax: 10 },
      open: "0"
    };
  },
  //创建
  created() {
    this.setBreadcrumb();
  },

  //安装
  mounted() {},

  //方法
  methods: {
    setBreadcrumb() {
      let arr = this.$store.state.breadcrumbList;
      let name = "";
      if (this.active == "1") {
        name = "在线计算";
      } else if (this.active == "2") {
        name = "历史结果";
      } else {
        name = "方案查询";
      }
      if (arr[0] && arr[0].name == "模态分析") {
        if (arr.length > 1) {
          arr.splice(1);
        }
      } else {
        if (arr.length > 2) {
          arr.splice(2);
        }
      }

      arr.push({ name });

      this.$store.commit("getBreadcrumb", arr);
    },
    changeState(e) {
      this.open = e;
    },
    changeTab(id) {
      this.active = id;
      this.setBreadcrumb();
    },

    addData() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      console.log("打开窗口");
      this.$bus.$emit("modelAdd", "");
      this.open = 1;
    }
  }
};
</script>
<style scoped lang="scss">
.modelIndex {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  // border: 1px solid  red;
  // overflow-y: hidden;
  .ul1 {
    margin-bottom: 28px;

    li {
      // width: 140px;
      // padding: 0 20px;
      color: #ccdaff;
      // border-right:  1px solid #3A4A7B;
    }

    li:last-child {
      border-right: none;
    }
  }

  .tab1 {
    color: #fff !important;
  }

  // .fu{
  //     position: relative;
  //     width: 100%;
  //     top: -5px;
  //     img{
  //         width: 100%;
  //     }
  // }
  .heng {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    box-shadow: 0px -1px 4px 0px #ffffff;
    opacity: 0.32;
    border: 1px solid #1f61ff;
  }
}

.mainBox {
  height: 100%;
}

.main {
  height: 100%;
  overflow: hidden;
}

.main2 {
}

.ulTab {
  .fu {
    position: relative;
  }

  li {
    position: relative;
    cursor: pointer;
  }

  .tiao {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 8px;
    background: url("../../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }

  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #ccc;
  }
}
</style>
