<template>
  <div class="main">
    <div class="box mainBox">
      <!-- 左侧 -->
      <div class="mR20 noFlex box box_column" style="width: 65%">
        <div class="box around">
          <el-select @change="clearData" v-model="ruleForm.period">
            <el-option
              v-for="item in periodList"
              :key="item.name"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="box end">
          <div class="box center">
            <div class="shuColor mR5"></div>
            <div class="fs12">频率</div>
          </div>
        </div>
        <div class="myChart1"  id="myChart1"></div>

        

        <div class="myChart mT10" v-show="!resultFlag" id="myChart"></div>
        <div class="noDataMain" v-show="resultFlag">
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>
        <div class="dataMain flex1 box box_column" v-if="pinlvList.length > 0">
          <ul class="dataList box center dataList1">
            <li>阶数</li>
            <li v-for="(item, index) in pinlvList" :key="index">
              {{ index + 1 }}
            </li>
          </ul>
          <ul class="dataList box center dataList2">
            <li>频率</li>
            <li v-for="(item, index) in pinlvList" :key="index">
              {{ parseInt(item) == 9999 ? "-" : item }}
            </li>
          </ul>
          <ul class="dataList box center dataList3 flex1">
            <li>阻尼比</li>
            <li v-for="(item, index) in zuniList" :key="index">
              {{ parseInt(item) == 9999 ? "-" : item }}
            </li>
          </ul>
        </div>

        <!-- <div class="noDataMain" v-if="!showMain">
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>
         -->
      </div>
      <!-- 右侧 -->
      <!-- :span-method="objectSpanMethod" -->
      <div style="" class="rightBox flex1">
        <el-table
          class="historyTable"
          :row-class-name="tableRowClassName"
          :data="tableData"
          style="width: 100%"
          @row-click="clickData"
        >
          <el-table-column
            align="center"
            width="240px"
            label="计算时间"
            prop="stime"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="优势频率（Hz）"
            prop="frequency"
          >
          </el-table-column>
          <el-table-column align="center" label="阻尼比" prop="dampingRatio">
          </el-table-column>
        </el-table>
        <div class="mT24">
          <common-pagination
            v-if="tableData.length > 0"
            class="pageMain"
            :total="total"
            :currentPage="currentPage"
            :pageSize="pageSize"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import CommonPagination from "../../../components/Pagination";
let option = {
  dataZoom: [
    {
      type: "inside",
      realtime: true,
      start: 0,
      end: 100,
    },
  ],
  grid: {
    top: "50",
    left: "40",
    right: "20",
    bottom: "60",
  },
  xAxis: {
    nameTextStyle: {
      color: "#fff",
      padding: [61, 0, 0, -40],
    },
    name: "f(Hz)",
    axisLabel: {
      color: "#fff",
      fontSize: 14,
    },
    axisLine: {
      lineStyle: {
        color: "#33393A",
        type: "dashed",
      },
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      lineStyle: {
        color: "#33393A",
      },
    },
  },
  yAxis: [
    {
      name: "number of poles",
      nameTextStyle: {
        color: "#DAF704",
        padding: [0, 0, 10, 100],
        fontSize: 16,
      },
      type: "value",
      axisLabel: {
        color: "#fff",
        fontSize: 14,
      },
      axisLine: {
        lineStyle: {
          color: "#979797",
        },
      },
      splitLine: {
        lineStyle: {
          color: "#33393A",
          type: "dashed",
        },
      },
      axisTick: {
        show: false,
      },
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#979797",
        },
      },
      axisTick: {
        show: false,
      },
    },
  ],
  legend: {
    data: [
      { name: "new pole" },
      { name: "stable pole" },
      { name: "stable freq. & MAC" },
      { name: "stable freq. & damp." },
      { name: "stable freq." },
      {
        name: "pwelch谱值",
        icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADaADAAQAAAABAAAADQAAAACpyhiuAAAAIUlEQVQoFWNgGH6AsevF//+keouJVA0g9YNcEzleGux6AG4JA3rtz4nzAAAAAElFTkSuQmCC",
      },
    ],
    itemHeight: 11,
    itemWidth: 11,
    right: 30,
    textStyle: {
      color: "#fff",
      padding: [0, 10, 0, 0],
    },
    itemGap: 40,
  },
  tooltip: {
    show: true,
    trigger: "axis",
    // confine:true
  },
  series: [
    {
      name: "new pole",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol:
        "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAACwAAAAChLCZNAAAArElEQVQoFWNkQAO5//+z/7nPcIuZhcFyihzjMzRpBiZ0Aa73DBxAMTmghBC6HIiPoQGbImQxkjUw5r79z8fwnYEPZsrfPwy8//8xXGNgZnBlYWa4ARP/85fh63R5xveMWff+P/7PwCADk8BFMzIw/BRjZBBgYeZn0CbWhgZ5xh9Ajaig/N1//k8fGD6wsjDoTpJjvIIqS49QwgjWb4IMP4DOePSPgeEdunNAfADyMTND4mn9UAAAAABJRU5ErkJggg==",
      itemStyle: {
        color: "#69DEFF",
      },
    },
    {
      name: "stable pole",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol: "circle",
      itemStyle: {
        color: "#FF7575",
      },
    },
    {
      name: "stable freq. & MAC",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol:
        "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAACwAAAAChLCZNAAABIUlEQVQoFY1RsUoDURCcfXfGykYrCxGEcOJ1FlYBwc7WIlUMkYBg5Q8YCJgf8AuUIIHYWthZiYWdhlMTQQgi2NnYhHt367wjZ5UEt3j7hp3ZHXYF49CovojENiBaAnQdKm/M9/C8loQXXznPuI8+7W8jiXskW6IjmIVleKiz9I0kfdSoupsLRF+qS7Dag/ErEp7f5oU8k7yFFNcoYFOC9qeBRQOQy0lkJ5Kw/cB0hpG2HDZQlOi368DUMHNXEPIYhr4DFLzBVLIrbKy8s+mqatPnBOkjjoOZguePNTYeijQtJ+COTsszBWlcpnXynCUfp1xlRaODnUmibEvAMeblxNXFPdkdIB2iDlGXd3gFfopIdI+UQ96kxm3d/Aky0T8v/QswsmtziQfLwgAAAABJRU5ErkJggg==",
      itemStyle: {
        color: "#FFD25D",
      },
    },
    {
      name: "stable freq. & damp.",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol: "rect",
      itemStyle: {
        color: "#99ED6A",
      },
    },
    {
      name: "stable freq.",
      symbolSize: 8,
      data: [],
      type: "scatter",
      symbol:
        "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAAt0lEQVQoFZVSORLCMAxc6aHQQQOBHlLBA0hSAZX5Ev+xkOyxsTMOM7jxoV1ptTKJO70BetD6OuHHEtfvILJhgO+ADPLquyW8uPMe8CMIz4AxtlbyMVDTcmye0MBzUgtMZb5YQQbt6aDlvWqeQHyk1WVMuIpgj1+SXmZgi2vT/62KkLNr5iBL/G3Rvdx04UbrLehpuZGEZlKYhdY1cBxK7UYi2J6lgju2cZve0roSbOf4bVh/gt9+AGA+gP/tCFrMAAAAAElFTkSuQmCC",
      itemStyle: {
        color: "#FFA07B",
      },
    },
    {
      name: "pwelch谱值",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#8AE8FF",
      },
      lineStyle: {
        width: 1,
      },
    },
  ],
};
export default {
  name: "modalAnalysis",
  components: {
    CommonPagination,
  },
  data() {
    return {
      resultFlag:false,
      showMain: false,
      projectId: "",
      ruleForm: {
        period: "",
        startTime: "",
        endTime: "",
        year: "",
        modalTimeLag: 10,
        modalNMin: 1,
        modalNMax: 10,
      },
      rules: {},
      periodList: [
        { name: "历史查询", id: 1 },
        { name: "重新计算", id: 2 },
      ],
      // jieshuList:[1,2,3,4,5,6],
      pinlvList: [],
      zuniList: [],
      tableColumns: [],
      tableData: [],
      isShowMain: false,
      loading: false,

      pageSize: 10, //
      currentPage: 1,
      total: 0,
    };
  },
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },
  mounted() {
    this.listData(); //下拉菜单
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    //  获取下拉菜单
    listData() {
      this.$axios
        .get(`${this.baseURL}modal/select/list/${this.projectId}`)
        .then((res) => {
          console.log("下拉信息", res.data.data);
          this.periodList = res.data.data;
          if (this.periodList.length) {
            this.ruleForm.period = this.periodList[0].id;
            this.clearData();
          }
        })
        .catch((err) => {});
    },

    // 选择下拉菜单
    clearData() {
      this.$axios
        .get(
          `${this.baseURL}modal/page/version/${this.ruleForm.period}?pageNum=${this.currentPage}&pageSize=${this.pageSize}`
        )
        .then((res) => {
          console.log("res", res);
          res = res.data.data;
          this.tableData = res.records;
          this.total = res.total;

          this.getTableAll();
          //  清空阶数表格
          this.pinlvList = [];
          this.zuniList = [];
          option.series[0].data = [];
          option.series[1].data = [];
          option.series[2].data = [];
          option.series[3].data = [];
          option.series[4].data = [];
          option.series[5].data = [];

          let myChart = echarts.init(document.getElementById("myChart"));
          myChart.clear();
          myChart.setOption(option);
          window.addEventListener("resize", () => {
            myChart.resize();
          });

          if (this.tableData.length) {
            this.clickData(this.tableData[0]);
          }
        });
    },

    // frequency
    getTableAll() {
      var number = "10000";
      this.$axios
        .get(
          `${this.baseURL}modal/page/version/${this.ruleForm.period}?pageNum=1&pageSize=${number}`
        )
        .then((res) => {
          console.log("res 全部图表信息", res);
          var res = res.data.data.records;
          var x = [];
          var y = [];
          var y1 = [];
          res.forEach((item, index) => {
            x.push(item.createTime);
            y.push(item.frequency);
            y1.push(item.dampingRatio);
          });
          this.$chart1.line11("myChart1", x, y, y1, "", false);
        });
    },

    // 点击日期 查找图标
    clickData(row, event, column) {
      console.log(row, event, column);
      this.getLineData(row.id);
    },

    // 点击分页
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.clearData();
    },

    queryData() {
      let period = this.ruleForm.period;
      this.isShowMain = true;
      if (period === 1) {
        // 历史计算
        this.getTable();
      } else {
        // 重新计算
        if (this.ruleForm.modalNMin >= this.ruleForm.modalNMax) {
          this.$message({
            message: "最低阶次应小于最高阶次",
            type: "warning",
          });
          return;
        }
        this.$nextTick(() => {
          this.getLineData();
        });
      }
    },

    // 表格跨行
    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 0) {
    //     if (rowIndex % 2 === 0) {
    //       return {
    //         rowspan: 2,
    //         colspan: 1
    //       };
    //     } else {
    //       return {
    //         rowspan: 0,
    //         colspan: 0
    //       };
    //     }
    //   }
    // },
    initEcharts() {
      let myChart = echarts.init(document.getElementById("myChart"));
      console.log("myChart", myChart);
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

    // 请求图表
    getLineData(id) {
      let projectId = this.$store.state.projectId;
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //   ${this.ruleForm.period}
      this.$axios
        .get(`${this.baseURL}modal/select/version/${id}`)
        .then((res) => {
          console.log("3333模态返回", res);
          res = res.data;
          console.log(66, res);
          this.loadingPage.close();
          // return;
          let data = res.data;

          if(data && data.length){
            let hasTag = false
            for (let i = 0; i < data.length; i++) {
              if (data[i].length) {
                hasTag = true
              }
            }
            if(hasTag){
              this.resultFlag = false
            }else{
              this.resultFlag = true
            }
          }else{
            this.resultFlag = true
          }

          this.loadingPage.close();
          if (res.errCode === "200") {
            /*图表散点展示：第一组：new pole
          第二组：stable pole
          第三组：stable freq. & MAC
          第四组：stable freq. & damp.
          第五组：stable freq.
          图表折线展示：第六组：[频率，幅值]列表展示：第七组：[频率，阻尼比]*/
            console.log(data);
            let arr = [];
            for (let i = 0; i < data.length; i++) {
              if (data[i].length) {
                arr.push("a");
              }
            }
            // this.showMain = false;
            // if (!arr.length) {
            //   this.showMain = true;
            //   return;
            // }

            option.series[0].data = data[0];
            option.series[1].data = data[1];
            option.series[2].data = data[2];
            option.series[3].data = data[3];
            option.series[4].data = data[4];
            option.series[5].data = data[5];

            let pinlvList = [];
            let zuniList = [];
            this.pinlvList = [];
            this.zuniList = [];
            /*for (let item of data[6]) {
            pinlvList.push(item[0].toFixed(4))
            zuniList.push(item[1].toFixed(4))
          }*/
            //   for (let i = 0;i<data[6].length/2;i++) {
            //     let item = data[6][i]
            //     pinlvList.push(item.toFixed(4))
            //   }
            //   for (let i = data[6].length/2;i<data[6].length;i++) {
            //     let item = data[6][i]
            //     zuniList.push(item.toFixed(4))
            //   }
            //   this.pinlvList = pinlvList
            //   this.zuniList = zuniList
            //   console.log(pinlvList)
            //   console.log(zuniList)
            var data6 = data[6];
            data6.forEach((item, index) => {
              pinlvList.push(item);
            });
            var data7 = data[7];
            data7.forEach((item, index) => {
              zuniList.push(item);
            });

            this.pinlvList = pinlvList;
            this.zuniList = zuniList;

            console.log(pinlvList);
            console.log(zuniList);

            this.initEcharts();
          } else {
            this.$message.error(res.errMsg);
          }

          this.initEcharts();
        });
    },

    // 获取右侧表格
    getTable() {
      let projectId = this.$store.state.projectId;
      this.$axios
        .get(`${this.baseURL}modal/page/version/1?pageNum=1&pageSize=10`)
        .then((res) => {
          console.log("res", res);
          res = res.data.data.records;
          this.tableData = res;
          this.getTableAll(); //全部日期图表

          this.pinlvList = [];
          this.zuniList = [];
          option.series[0].data = [];
          option.series[1].data = [];
          option.series[2].data = [];
          option.series[3].data = [];
          option.series[4].data = [];
          option.series[5].data = [];

          let myChart = echarts.init(document.getElementById("myChart"));
          myChart.clear();
          myChart.setOption(option);
          window.addEventListener("resize", () => {
            myChart.resize();
          });

          if (this.tableData.length) {
            this.clickData(this.tableData[0]);
          }

          // if (res.length) {
          //   this.getLineData(res[0].id);
          // } else {
          //   this.showMain = true;
          // }

          //   this.$chart1.line('echarts1', x, y,unit,'', false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-select {
  width: 340px;
  height: 32px;

  .el-input {
    height: 100%;
  }

  .el-input__icon {
    line-height: 32px;
  }
}

.main {
  height: 100%;
  margin-top: 10px;

  ::v-deep .el-form {
    margin-top: 20px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 102px;
      padding: 0;
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }

    .line {
      padding: 0 10px;
    }

    .el-input {
      width: 134px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        width: 162px;

        .el-input__inner {
          padding: 0 0 0 10px;
          font-size: 14px;
        }

        .el-input__prefix {
          display: none;
        }
      }
    }

    .el-button.ml24 {
      margin-left: 24px;
    }
  }

  .myChart {
    width: 100%;
    height: 290px;
    overflow: hidden;
    //height: 420px;
  }

  .myChart1 {
    width: 100%;
    height: 190px;
    overflow: hidden;
    //height: 420px;
  }

  .dataMain {
    //   border: 1px solid #979797;
    border: 1px solid #2772f0;
    margin-right: 8px;
    margin-top: 30px;

    .dataList {
      // border-bottom: 1px solid #33393A;
      li {
        width: 14.28%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        border-left: 1px solid #33393a;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  ::v-deep .historyTable {
    border: 1px solid #31363a;

    thead.is-group.has-gutter {
      tr:nth-child(2) {
        display: none;
      }

      th {
        background-color: #31363a;
        border-color: #31363a;
      }
    }

    .el-table__body tr:hover > td {
      background-color: rgba(48, 46, 46, 0.11);
    }

    &.el-table--border::after,
    &.el-table--group::after {
      width: 0;
    }

    .el-table__header-wrapper {
      border-bottom: 1px solid #5e5e5f;
    }

    td {
      background-color: rgba(48, 46, 46, 0.11);
    }

    &.el-table td,
    &.el-table th.is-leaf {
      border-bottom: 1px solid #31363a !important;
    }

    &.el-table--border td,
    &.el-table--border th,
    &.el-table__body-wrapper
      .el-table--border.is-scrolling-left
      ~ .el-table__fixed {
      border-right: 1px solid #31363a !important;
    }
  }

  ::v-deep .smallInput {
    .el-input {
      width: 115px;

      .el-input__inner {
        padding: 0 15px;
      }
    }

    .el-input-number {
      width: 115px;

      span {
        display: none;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.mainBox {
  width: 100%;
  height: 100%;
}

.dataList1 {
  background: #0d2d5d;
}

.dataList2 {
  background: #0b2657;
}

.dataList3 {
  background: #13366b;
}

.rightBox {
  height: 100%;
  border: 1px solid #2772f0;
  margin-right: 20px !important;
}

.shuColor {
  width: 24px;
  height: 3px;
  background: #2772f0;
  border-radius: 2px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
